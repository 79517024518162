import React, { useEffect, useState } from "react"
import https from "../services/https"
import { API_URL } from "../primeagile.config"
import PaymentLinkCheckout from "../components/Builder/Components/Stripe/PaymentLinkCheckout"
import ElementComponent from "../components/Builder/Components/Stripe/ElementComponent"
import { Head } from "../components/Builder/Head"
import styled from "styled-components"
import { useRef } from "../hooks/stateHooks"
import { Notify } from "../components/Builder/Components/Notification/Notify"
import { navigate } from "gatsby"

const ContainerDiv = styled.div`
  padding: 4em;
  height: 100vh;
  button {
    font-size: clamp(1rem, 2.5vh, 1.5rem);
    font-weight: 500;
    background-color: #f360a6;
    color: white;
    padding: 15px 20px;
    outline: none;
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
  }

  .form-control {
    border-radius: 28px;
    height: 50px;
  }
  .content {
    display: flex;
    justify-content: center;
    gap: 2em;
    height: 80%;
    .payment {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-size: 1.2em;
        font-weight: bold;
        color: #f360a6;
      }

      form {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 25px;
      }
    }

    .contact {
      flex: 1;
      /* */

      justify-content: center;
      display: flex;
      flex-direction: column;

      .input-container {
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        /* height: 66%; */
      }

      .names {
        display: flex;
        gap: 10px;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 25px;

        label {
          font-size: 1.2em;
          font-weight: 500;
        }
      }
    }
  }

  @media screen and (max-width: 40rem) {
    padding: 1em;
    height: unset;

    .content {
      display: block;
    }

    .contact {
      margin-top: 1em;
    }
  }
`
const Pay = ({ location: { search } }) => {
  const paymentLinkID = search?.split("=")[1]
  const [paymentRecord, setPaymentRecord] = useState(null)
  const [secret, setSecret] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingLink, setLoadingLink] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [formdata, setFormdata] = useState({
    email: "",
    lastName: "",
    phone: "",
    firstName: "",
    entityName: "",
  })
  const updateRef = useRef(null)

  const url = `${API_URL}/open/payment-link?tenant=${process.env.GATSBY_BUILDSITE}&uuid=${paymentLinkID}`

  useEffect(() => {
    if (!paymentLinkID) return

    setLoadingLink(true)
    https(
      url,
      "get",
      undefined,
      undefined,
      res => {
        setPaymentRecord(res.data)
        setFormdata({
          email: "",
          firstName: res.data?.entity?.firstName || "",
          lastName: res.data?.entity?.lastName || "",
          entityId: res.data?.entity?.entityId,
          entityName: res.data?.entity?.entityName,
        })
        setLoadingLink(false)
      },
      err => {
        setError(err.message)
        setLoadingLink(false)
      }
    )
  }, [paymentLinkID])

  useEffect(() => {
    if (!paymentRecord?.amount) return

    setLoading(true)
    const price = paymentRecord.amount
      ?.toLocaleString("en", { minimumFractionDigits: 2 })
      .replace(/[^a-zA-Z0-9]/g, "")

    const data = {
      amount: price,
      applicationFeeAmount: 0,
      automaticPaymentMethods: { enabled: false, allow_redirects: "NEVER" },
      currency: paymentRecord.currency || "usd",
      paymentMethodTypes: [
        Number(paymentRecord.amount) > 500 ? "us_bank_account" : "card",
      ],
      tenant: process.env.GATSBY_BUILDSITE,
    }

    https(
      `${API_URL}/open/commerce/stripe/payment_intent`,
      "post",
      data,
      undefined,
      res => {
        setSecret(res.data.clientSecret)
        setLoading(false)
      },
      err => {
        setError(err.message)
        setLoading(false)
      }
    )
  }, [paymentRecord])

  function handleSuccess(item) {
    const data = {
      entityId: paymentRecord.entity?.entityId,
      paymentW: {
        confirmationCode: item.paymentIntent.id,
        amount: item.paymentIntent.amount,
        gatewayId: Number(1051),
        status: item.paymentIntent.status,
        channel: item.paymentIntent.payment_method,
        jsonData: {},
      },
      orderItemWList: [],
      jsonData: {
        merchantType: "Stripe",
        email: formdata.email,
        gatewayId: Number(1051),
        paymentDate: new Date(),
      },
      orderNotificationEmails: [formdata.email],
      status: "NEW",
    }

    const link = `${API_URL}/open/commerce/order?client=${process.env.GATSBY_BUILDSITE}`
    https(
      link,
      "post",
      data,
      undefined,
      res => {
        Notify({ type: "success", text: "Payment successful" })
        navigate("/")
      },
      error => {
        setIsLoading(false)
      }
    )
  }

  function handleChange(e) {
    const { value, name } = e.target
    setFormdata(p => ({
      ...p,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    const { firstName, lastName, email, phone, entityName } = formdata

    if (!email || !phone) {
      Notify({ type: "error", text: "Please fill all personal information" })
      return
    }

    updateRef.current.submit()
  }

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    )
  }

  if (!paymentLinkID) {
    return (
      <div>
        <h1>Payment link not provided</h1>
      </div>
    )
  }

  if (loadingLink) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="spinner text-primary" role="status"></div>
          <h2>Loading payment link...</h2>
        </div>
      </Container>
    )
  }

  if (!paymentRecord) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>
            Unable to fetch payment record. Please check with your provider.
          </h2>
        </div>
      </Container>
    )
  }

  if (loading) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="spinner text-primary" role="status"></div>
          <h2>Creating payment intent...</h2>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      {secret ? (
        <ElementComponent secret={secret}>
          <div className="content">
            <div className="payment">
              <h1>Payment</h1>
              <div>
                <h2>
                  Payment of:{" "}
                  {paymentRecord.currency === "usd"
                    ? "$"
                    : paymentRecord.currency}
                  {paymentRecord.amount
                    ? Number(paymentRecord.amount).toLocaleString()
                    : 0}
                </h2>
                <h2>Entity: {paymentRecord.entity?.entityName}</h2>
              </div>
              <PaymentLinkCheckout
                handleSuccess={handleSuccess}
                updateRef={updateRef}
                setIsLoading={setIsLoading}
              />
            </div>
            <div className="contact">
              <h1>Personal information</h1>
              <div className="input-container">
                <div className="inputs">
                  {/* <div className="names">
                    <div style={{ flex: "1" }}>
                      <label>First Name</label>
                      <input
                        placeholder="First Name"
                        className="form-control"
                        onChange={e => handleChange(e)}
                        name="firstName"
                        value={formdata.firstName}
                        required
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label>Last Name</label>
                      <input
                        placeholder="Last Name"
                        className="form-control"
                        onChange={e => handleChange(e)}
                        name="lastName"
                        value={formdata.lastName}
                        required
                      />
                    </div>
                  </div> */}
                  <div>
                    <label>Entity Name</label>
                    <input
                      placeholder="Entity Name"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      name="entityName"
                      required
                      value={formdata.entityName}
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      placeholder="Email"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      name="email"
                      required
                      value={formdata.email}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <input
                      placeholder="Phone Number"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      name="phone"
                      value={formdata.phone}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2em",
            }}
          >
            <button
              disabled={isLoading}
              id="submit"
              onClick={() => handleSubmit()}
            >
              <span id="button-text">
                {isLoading ? "Processing" : "Pay now"}
              </span>
            </button>
          </div>
        </ElementComponent>
      ) : (
        "Unable to process payment."
      )}
    </Container>
  )
}

export default Pay

const Container = ({ children }) => {
  return (
    <ContainerDiv>
      <Head />
      {children}
    </ContainerDiv>
  )
}
